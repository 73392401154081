import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'result']
  static values = {
    url: String,
  }

  connect() {
    if (typeof google !== 'undefined') {
      this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, this.autocompleteOptions)
      this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
      this.inputTarget.addEventListener('keydown', this.preventSubmit.bind(this));
      this.token = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;
    } else {
      console.error('>Service location not available at this moment. Please try again in a few seconds.')
      this.inputTarget.parentNode.innerHTML = `
        <div class="my-8 bg-amber-100 py-3 px-x">Service location not available at this moment. Please try again in a few seconds.</div>
        <p><a class="btn btn-darker btn-full" href="#" data-action="places#retry">Try again!</a></p>
        `;
    }
  }

  placeChanged() {
    const place = this.autocomplete.getPlace()
    const data = { reference: place.place_id }

    if (data.reference === undefined) {
      return
    }

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then(response => response.text())
      .then(html => this.resultTarget.innerHTML = html)
  }

  preventSubmit(event) {
    if (event.code === 'Enter') {
      event.preventDefault()
    }
  }

  retry(event) {
    event.preventDefault()
    document.location.href = '/'
  }

  get autocompleteOptions() {
    return {
      fields: ['place_id', 'name', 'formatted_address', 'business_status', 'geometry', 'photos'],
      // types: ['dentist']
    }
  }
}
