import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message", "waiting"];
  static values = { type: String }

  connect() {
    this.contentLength = this.messageTarget.textContent.length;
    this.duration = this.calculateDuration(this.contentLength);

    if (this.typeValue !== 'error') {
      this.startWaitingAnimation();
    }
  }

  calculateDuration(contentLength) {
    const baseDuration = 3000; // Base duration in milliseconds (e.g., 3 seconds)
    const additionalTime = contentLength * 50; // Add time based on content length
    return baseDuration + additionalTime;
  }

  startWaitingAnimation() {
    this.waitingWidth = 0;
    this.intervalDuration = this.duration / 100; // Calculate the interval duration based on content length
    this.waitingID = setInterval(this.frame.bind(this), this.intervalDuration);
  }

  frame() {
    if (this.waitingWidth >= 100) {
      clearInterval(this.waitingID);
      this.messageTarget.remove();
    } else {
      this.waitingWidth++;
      this.waitingTarget.style.width = `${this.waitingWidth}%`;
    }
  }

  close(event) {
    event.preventDefault();
    clearInterval(this.waitingID);
    this.messageTarget.remove();
  }
}
