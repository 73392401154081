import { Controller } from "stimulus";
import dataTable from "datatables.net";

export default class extends Controller {
  static targets = ['table'];
  static STORAGE_VERSION = '1.0';

  connect() {
    if (this.hasTableTarget) {
      $(this.tableTargets).dataTable({
        retrieve: true,
        info: false,
        dom: '<"table-toolbar"f>rt<"table-footer"p>',
        pageLength: 10,
        lengthChange: false,
        stateSave: true,
        stateSaveParams: this.stateSaveParams.bind(this),
        stateSaveCallback: this.stateSaveCallback.bind(this),
        stateLoadCallback: this.stateLoadCallback.bind(this),
        columnDefs: this.getNoSortColumns().map(index => ({ targets: index, orderable: false })),
        fnDrawCallback: this.handleDrawCallback.bind(this),
      });

      this.initializeRowClickHandler();
    }
  }

  stateSaveParams(_settings, data) {
    delete data.search;
  }

  stateSaveCallback(settings, data) {
    const tableId = `DataTables_${settings.sInstance}_${this.constructor.STORAGE_VERSION}`;
    localStorage.setItem(tableId, JSON.stringify(data));
  }

  stateLoadCallback(settings) {
    const tableId = `DataTables_${settings.sInstance}_${this.constructor.STORAGE_VERSION}`;
    return JSON.parse(localStorage.getItem(tableId));
  }

  handleDrawCallback(oSettings) {
    $('.dataTables_filter input').addClass('form-input');
    const filterVisibility = oSettings.fnRecordsTotal() <= 5 ? 'hide' : 'show';
    $('.dataTables_filter')[filterVisibility]();

    const table = $(oSettings.nTable);
    const searchPlaceholder = $(table).data().searchPlaceholder;
    if (searchPlaceholder) {
      $('.dataTables_filter input').attr('placeholder', searchPlaceholder);
    } else {
      $('.dataTables_filter input').attr('placeholder', '');
    }

    const pagination = $(oSettings.nTableWrapper).find('.dataTables_paginate');
    pagination.toggle(oSettings._iDisplayLength < oSettings.fnRecordsDisplay());
  }

  initializeRowClickHandler() {
    const tableElement = this.tableTargets[0];
    const headerCells = tableElement.getElementsByTagName('th');

    $(tableElement).on('click', 'tbody tr', (event) => {
      const row = event.currentTarget;
      const link = row.dataset.link;
      const clickedColumnIndex = $(event.target).closest('td').index();

      if (!headerCells[clickedColumnIndex].hasAttribute('data-noclick')) {
        window.location.href = link;
      }
    });

    $(tableElement).find('tbody tr td').each(function (index) {
      const headerCell = headerCells[$(this).index()];
      if (!headerCell.hasAttribute('data-noclick')) {
        $(this).css('cursor', 'pointer');
      }
    });
  }

  getNoSortColumns() {
    const noSortColumns = [];
    $(this.element).find('[data-target="datatable.table"] thead th').each((index, th) => {
      if ($(th).data('nosort') !== undefined) {
        noSortColumns.push(index);
      }
    });
    return noSortColumns;
  }

  disconnect() {
    if (this.hasTableTarget) {
      $(this.tableTargets).each((_index, element) => {
        if ($.fn.DataTable.isDataTable(element)) {
          $(element).DataTable().destroy();
        }
      });
    }
  }
}
