import { Controller } from "stimulus"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { debounce } from "lodash";
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['map', 'place', 'filters', 'more']
  static values = {
    markerIcon: String,
    coordinates: Array,
  }

  connect() {
    this.placeNumber = 1
    this.userInteracted = false;  // Flag to track user interaction

    // Set thresholds
    this.panThreshold = 5000;  // Pan threshold in meters
    this.zoomThreshold = 10;   // Zoom threshold levels

    if (this.hasMapTarget) {
      this.initMap()
      this.panToPlace = debounce(this.panToPlace, 500).bind(this)
    }

    if (this.hasMoreTarget) {
      useIntersection(this, {
        element: this.moreTarget,
        rootMargin: "500px"
      });
    }
  }

  initMap() {
    L.NumberedDivIcon = L.Icon.extend({
      options: {
        iconUrl: this.markerIconValue,
        number: '',
        shadowUrl: null,
        iconSize: new L.Point(32, 32),
        iconAnchor: new L.Point(32, 32),
        popupAnchor: new L.Point(-16, -16),
        className: 'leaflet-div-icon'
      },

      createIcon: function () {
        var div = document.createElement('div');
        var img = this._createImg(this.options['iconUrl']);
        var numdiv = document.createElement('div');
        numdiv.setAttribute("class", "number");
        numdiv.innerHTML = this.options['number'] || '';
        div.appendChild(numdiv);
        this._setIconStyles(div, 'icon');

        return div;
      },

      createShadow: function () {
        return null;
      }
    });

    $(this.mapTarget).css('width', '100%')
    $(this.mapTarget).css('height', '100%')

    this.map = L.map(this.mapTarget, {
      center: this.coordinatesValue,
      zoom: 12,
      attributionControl: false,
      scrollWheelZoom: false,
      zoomControl: false,
    });

    this.initialCenter = this.map.getCenter();  // Store initial center
    this.initialZoom = this.map.getZoom();      // Store initial zoom level

    this.map.whenReady(() => {
      this.markPlaces();
    });

    L.control.zoom({
      position: 'bottomright'
    }).addTo(this.map);

    L.tileLayer('https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=861f8430-5522-429d-b2cc-075ab20b0a54', {
      maxZoom: 20,
    }).addTo(this.map);

    this.markerLayerGroup = L.layerGroup().addTo(this.map);

    this.map.on('moveend', () => {
      if (this.userInteracted) {
        this.checkThresholds();
      }
    });

    this.map.on('zoomend', () => {
      if (this.userInteracted) {
        this.checkThresholds();
      }
    });

    setTimeout(() => {
      this.userInteracted = true;
    }, 1000);
  }

  checkThresholds() {
    const currentCenter = this.map.getCenter();
    const currentZoom = this.map.getZoom();

    const panDistance = currentCenter.distanceTo(this.initialCenter);  // Distance in meters
    const zoomDifference = Math.abs(currentZoom - this.initialZoom);

    if (panDistance > this.panThreshold || zoomDifference > this.zoomThreshold) {
      this.showSearchArea();
    }
  }

  showSearchArea() {
    // document.getElementById('map-search-area').classList.remove('hidden');
  }

  appear(entry) {
    const $button = $(entry.target).find('input[type="submit"]')

    if ($button.length) {
      $button.trigger('click')
      $button.addClass('btn-disabled')
      $button.prop('value', 'Loading more ...');
      setTimeout(function () {
        $button.removeClass('btn-disabled')
        $button.prop('value', 'Show more');
      }, 3000);
    }
  }

  markMyLocation(e) {
    const radius = e.accuracy / 2;

    L.marker(e.latlng, {
      icon: new L.NumberedDivIcon({
        number: `
        <svg width="24" height="24" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M20.94 11A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06ZM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm-7 4c0 3.87 3.13 7 7 7s7-3.13 7-7-3.13-7-7-7-7 3.13-7 7Z" clip-rule="evenodd"></path>
        </svg>     
        `
      })
    }).addTo(this.map)
      .bindPopup("Your approximate location");
  }

  markPlaces() {
    if (this.hasPlaceTarget) {
      this.markers = [];
      const numberedDivIconOptions = {
        title: '',
        riseOnHover: true,
        opacity: 0.85
      };

      $(this.placeTargets).each((_index, place) => {
        const latitude = $(place).attr('data-latitude');
        const longitude = $(place).attr('data-longitude');
        const name = $(place).attr('data-name');
        const address = $(place).attr('data-address');
        const reference = $(place).attr('data-reference');
        const url = $(place).attr('data-office-drawer--component-url-param');

        $(place).find('.place-card-number').html(this.placeNumber);

        const marker = L.marker([latitude, longitude], {
          icon: new L.NumberedDivIcon({ number: this.placeNumber }),
          ...numberedDivIconOptions
        });
        marker.id = reference;
        marker.addTo(this.map).bindPopup(name);

        const popupContent = `
          <a href="#" data-action="click->office-drawer--component#show" data-office-drawer--component-url-param="${url}">
            <h6 class="mb-1">${name}</h6>
          </a>
          <p>${address}</p>
        `;
        marker._popup.setContent(popupContent);

        this.placeNumber += 1;

        this.markers.push(marker);
      });

      this.markersGroup = L.featureGroup(this.markers);
      this.map.fitBounds(this.markersGroup.getBounds());
    }
  }

  panToPlace(event) {
    if (!this.map) return;

    let $placeCard = $(event.target).closest('.place-card');

    const latitude = $placeCard.attr('data-latitude')
    const longitude = $placeCard.attr('data-longitude')
    const reference = $placeCard.attr('data-reference')

    this.map.closePopup();

    let marker = this.markers.find(o => o.id === reference);
    if (marker) {
      marker.openPopup()
    }
  }

  toggleFilters(event) {
    event.preventDefault();
    $(this.filtersTarget).toggleClass('hidden')
  }

  disconnect() {
    if (this.map) {
      this.map.off();
      this.map.remove();
    }
  }
}
