import { Controller } from "stimulus";
import { Popover } from 'flowbite';

export default class extends Controller {
  static targets = ['target', 'trigger'];

  connect() {
    this.popover = new Popover(this.targetTarget, this.triggerTarget, this.options);
  }

  get options() {
    return {
      placement: 'top',
      triggerType: 'click',
      offset: 10,
      onToggle: this.handlePopoverToggle.bind(this),
    };
  }

  handlePopoverToggle() {
    const [agentInvitePanel, autoInvitePanel] = this.getPanels();
    if (this.popover.isVisible() && agentInvitePanel && autoInvitePanel) {
      agentInvitePanel.classList.remove('hidden');
      autoInvitePanel.classList.add('hidden');
    }
  }

  toggleInvitePanels(event) {
    event.preventDefault();
    event.stopPropagation();

    const [agentInvitePanel, autoInvitePanel] = this.getPanels();
    if (agentInvitePanel && autoInvitePanel) {
      this.popover.hide();
      setTimeout(() => {
        agentInvitePanel.classList.toggle('hidden');
        autoInvitePanel.classList.toggle('hidden');
        this.popover.show();
      }, 150);
    }
  }

  getPanels() {
    return [
      document.getElementById('referral-agent-invite-panel'),
      document.getElementById('referral-auto-invite-panel')
    ];
  }
}
