import Rails from "@rails/ujs"
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import "flowbite/dist/flowbite.turbo.min.js"

Rails.start()
ActiveStorage.start()
Turbo.setProgressBarDelay(1500)

require.context('../images', true)
