import { Controller } from "@hotwired/stimulus";

export default class RedirectController extends Controller {
  connect() {
    const url = this.element.dataset.redirectUrl;
    const quiet = this.element.dataset.redirectQuiet;

    if (url) {
      if (!quiet) {
        window.location.href = url;
      } else {
        history.pushState(null, "", url);
      }
    }
  }
}
