import { Controller } from "stimulus"

export default class Clipboard extends Controller {
  static targets = ["button", "source", "iconCopy", "iconCopied"]

  static TIMEOUT_DURATION = 5000

  connect() {
    if (!this.hasButtonTarget) return
    this.originalContent = this.buttonTarget.innerHTML

    if (this.hasSourceTarget) {
      this.sourceTarget.addEventListener('focus', this.selectAllText.bind(this))
    }
  }

  copy(event) {
    event.preventDefault()
    const text = this.sourceTarget.value || this.sourceTarget.innerHTML
    navigator.clipboard.writeText(text).then(() => {
      this.copied()
      this.hidePopperAndChangeText(event.target)
    })
  }

  copied() {
    this.iconCopyTarget.classList.add('hidden')
    this.iconCopiedTarget.classList.remove('hidden')
    setTimeout(() => {
      this.iconCopiedTarget.classList.add('hidden')
      this.iconCopyTarget.classList.remove('hidden')
      this.resetInviteButtonText()
    }, Clipboard.TIMEOUT_DURATION)
  }

  hidePopperAndChangeText(button) {
    const popper = button.closest('[data-target="popover.target"]')
    if (popper) {
      popper.classList.remove('opacity-100', 'visible')
    }

    const inviteButton = document.getElementById('popper-invite')
    if (inviteButton) {
      this.originalInviteButtonText = inviteButton.textContent
      inviteButton.textContent = "Link copied"
      inviteButton.classList.add('disabled')
    }
  }

  resetInviteButtonText() {
    const inviteButton = document.getElementById('popper-invite')
    if (inviteButton && this.originalInviteButtonText) {
      inviteButton.textContent = this.originalInviteButtonText
      inviteButton.classList.remove('disabled')
    }
  }

  // Automatically select all text when the source input is focused
  selectAllText(event) {
    event.target.select()
  }
}
