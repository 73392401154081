import { Controller } from "stimulus";
import VenoBox from "venobox";

export default class extends Controller {
  connect() {
    new VenoBox({
      selector: ".venobox-thumbnail",
      border: '30px',
      numeration: true,
      spinColor: "#16a0eb",
      spinner: "flow",
    });
  }

  remove(event) {
    const targetElement = event.target;

    const parentWidget = targetElement.closest(".thumbnail-wrapper");

    if (parentWidget) {
      parentWidget.remove();
    }
  }
}
