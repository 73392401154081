import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tooth", "type", "selection"];

  connect() {
    this.handleTeethFields(this.typeTarget.value)
    this.defaultSelection()
    this.form = this.selectionTarget.form
  }

  change(event) {
    this.clearSelection()
    this.handleTeethFields(event.currentTarget.value)
  }

  handleTeethFields(type) {
    $('[data-teeth-type]').addClass('hidden')

    if (type) {
      $(`[data-teeth-type="${type}"]`).removeClass('hidden')
    }
  }

  select(event) {
    event.currentTarget.classList.toggle("selected");
    this.selectionChanged()
  }

  selectionChanged() {
    let selection = []
    $(this.toothTargets).filter('.selected').each(function (_index, tooth) {
      selection.push($(tooth).attr('data-tooth-number'))
    });

    let sortedSelection = selection.sort(function (a, b) { return a - b })

    $(this.selectionTarget).val(sortedSelection.join(','))

    this.form.dispatchEvent(new Event("change", { bubbles: true }));
  }

  defaultSelection() {
    let selection = this.selectionTarget.value.split(',')

    $(this.toothTargets).each(function (_index, tooth) {
      if (selection.includes($(tooth).attr('data-tooth-number'))) {
        $(tooth).addClass('selected')
      } else {
        $(tooth).removeClass('selected')
      }
    });
  }

  clearSelection() {
    $(this.toothTargets).removeClass('selected')
    $(this.selectionTarget).val('')
  }
}