import { Controller } from "stimulus"
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = ['input', 'hidden', 'selected', 'list', 'item', 'search', 'dropdown'];

  connect() {
    this.handleClicks = this.handleClicks.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyboard = this.handleKeyboard.bind(this);
    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.search = this.search.bind(this);
    this.select = this.select.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.form = this.hiddenTarget.form;

    this.handleClicks();
    this.handleFocus();
    this.handleKeyboard();
    this.savedItemValues = this.saveCurrentItems();
    useMutation(this, { childList: true, subtree: true });
  }

  saveCurrentItems() {
    return this.itemTargets.map(item => item.dataset.value);
  }

  mutate() {
    const newItem = this.getNewItem();
    if (newItem) {
      this.selectNewItem(newItem);
    }
  }

  getNewItem() {
    const currentItems = this.saveCurrentItems();
    const newItems = currentItems.filter(value => !this.savedItemValues.includes(value));
    return newItems.length > 0 ? this.itemTargets.find(item => item.dataset.value === newItems[0]) : null;
  }

  selectNewItem(newItem) {
    this.hiddenTarget.value = newItem.dataset.value;
    this.selectedTarget.innerHTML = newItem.innerHTML;
    this.form.dispatchEvent(new Event("change", { bubbles: true }));
    this.savedItemValues = this.saveCurrentItems();
  }

  handleClicks() {
    window.addEventListener('click', (event) => {
      if (!this.dropdownTarget.contains(event.target) && !this.inputTarget.contains(event.target)) {
        this.hideDropdown();
      }
    });
  }

  handleFocus() {
    this.element.addEventListener("focusout", (event) => {
      if (!this.element.contains(event.relatedTarget)) {
        this.hideDropdown();
      }
    });
  }

  handleKeyboard() {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.hideDropdown();
      }
    });
  }

  showDropdown(_event) {
    this.resetSearch();
    this.dropdownTarget.classList.remove('hidden');
    if (this.hasSearchTarget) {
      this.searchTarget.focus();
    }
  }

  hideDropdown() {
    this.resetSearch();
    this.dropdownTarget.classList.add('hidden');
  }

  addNew(_event) {
    this.hideDropdown();
  }

  search(event) {
    const query = event.currentTarget.value;

    if (this.hasItemTarget) {
      this.itemTargets.forEach((item) => {
        if (this.searchForText(item, query)) {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        }
      });
    }
  }

  select(event) {
    this.hiddenTarget.value = event.currentTarget.dataset.value;
    this.selectedTarget.innerHTML = event.currentTarget.innerHTML;
    this.form.dispatchEvent(new Event("change", { bubbles: true }));
    this.hideDropdown();
  }

  resetSearch() {
    if (this.hasSearchTarget) {
      this.searchTarget.value = '';
      this.itemTargets.forEach((item) => {
        item.classList.remove('hidden');
      });
    }
  }

  searchForText(item, searchText) {
    const searchTextLower = searchText.toLowerCase();
    return item.innerHTML.toLowerCase().includes(searchTextLower);
  }
}
