import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import debounce from 'lodash.debounce';

export default class extends Controller {
  connect() {
    this.savingThreshold = 500; // Threshold for debounced saving
    this.savingDelay = 1500;
    this.savingTries = 10;
    this.currentTry = 0;
    this.formChanged = false; // Track if form has been changed
    this.save = debounce(this.save.bind(this), this.savingThreshold); // Debounce save
    this.form = this.element;
    this.persisted = document.getElementById('referral_persisted').value;
    this.footer = document.getElementById('footer');

    if (this.footer === null) {
      return;
    }

    // Track changes in form input and textarea elements
    this.handleInputListeners();

    // Handle turbo frame update to re-enable the form and footer
    document.addEventListener('turbo:before-fetch-response', () => {
      setTimeout(() => {
        // this.footer.classList.remove('disabled');
        this.form.classList.remove('disabled');
      }, 500);
    });
  }

  // Add input listeners to track changes without needing focus out
  handleInputListeners() {
    // Listen for change and input events on form inputs and textareas
    this.form.addEventListener('input', () => {
      this.formChanged = true;
      // this.footer.classList.add('disabled');
      if (this.persisted === 'false') {
        this.form.classList.add('disabled');
      }
      this.save(); // Trigger save on input with debouncing
    });

    this.form.addEventListener('change', () => {
      this.formChanged = true;
      this.save();
    });
  }

  save() {
    if (!this.formIsBusy) {
      this.currentTry = 0;
      if (window._rails_loaded) {
        Rails.fire(this.form, 'submit');
      } else {
        this.form.requestSubmit();
      }
    } else {
      if (this.currentTry < this.savingTries) {
        this.currentTry++;
        setTimeout(this.save, this.savingDelay);
      }
    }
  }

  get formIsBusy() {
    const formIsBusy = document.getElementById('referral_busy');
    return formIsBusy && formIsBusy.value === "true";
  }
}
